.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #222;
  color: #fff;
}

.login-container {
  padding: 20px;
  max-width: 400px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #333;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.LoggedInPage {
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  min-height: 100vh; /* To ensure it takes full viewport height */
  padding: 20px;
  box-sizing: border-box;
}

.RaportInput {
  width: 100%;
  max-width: 600px;
  
  padding: 20px;
  margin: 20px 0;
}

.userPanel {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin: 20px 0;
}

.adminPanelHolder {
  display: flex;
  width: 90vw;
  max-width: 1200px;
  flex-direction: row;
  background-color: #555;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.login-form {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

.login-form div {
  margin-bottom: 15px;
}

.login-form label {
  margin-bottom: 8px;
  font-weight: bold;
  display: block;
  color: #fff;
}

.login-form input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #444;
  color: #fff;
}
.user-list-container {
  max-height: 400px; /* Set the desired height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add a border to the container */
  padding: 10px; /* Optional: Add padding for better spacing */
}

.user-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc; /* Optional: Add a border to each item */
  border-radius: 5px; /* Optional: Rounded corners for each item */
}

hr {
  border: 0;
  border-top: 1px solid #eee;
  margin: 10px 0;
}


.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}
/* Popup styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background-color: #555;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  max-width: 90%;
}

.popup-content h3 {
  margin-top: 0;
}

.popup-content form {
  display: flex;
  flex-direction: column;
}

.popup-content form div {
  margin-bottom: 10px;
}

.popup-content label {
  margin-bottom: 5px;
  font-weight: bold;
}

.popup-content input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.popup-content button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.popup-content button[type="submit"] {
  background-color: #28a745;
  color: #fff;
}

.popup-content button[type="button"] {
  background-color: #dc3545;
  color: #fff;
}

