.Container {
  position: relative;
  display: flex;
  background-color: rgb(255, 255, 255);
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

.grayScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.8); /* semi-transparent gray */
  z-index: 1000; /* High z-index to ensure it covers everything */
  pointer-events: none; /* Disable all interactions */
}

.grayScreen.disabled {
  pointer-events: none; /* Disable interactions with the main content */
}
.grayText {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #333;
  font-size: 18px;
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.8); /* Optional: background for better readability */
  padding: 20px; /* Optional: padding for better spacing */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
}
.grayScreen {
  pointer-events: none; /* Disable interactions with the main content */
}


.hero {
  position: absolute;
  top: 0;
  background: linear-gradient(to bottom, rgba(37, 43, 67, 0.6), rgba(37, 43, 67, 1)), url('../assets/bilder/bg.png') no-repeat center center;
  background-size: cover;
  height: 100vh;
  width:100%;
  align-content: center;
  justify-content: center;
}
.herocontent {
  height: 80vh;
  width: 90%;
  margin: 0 auto; /* This centers the element horizontally */
  display: flex; /* Ensure Flexbox is used if needed */
  justify-content: center; /* Centers content horizontally inside herocontent if needed */
}
.col1 {
  flex: 0 0 45%; /* Each column takes up 50% of the width */
  margin: 0 5%; /* Optional: Add padding for spacing between columns */
  align-content: center;
}  
.col2 {
  flex: 0 0 45%; /* Each column takes up 50% of the width */
  margin: 0 5%;
  align-content: center;
}
.col1mid {
  flex: 0 0 50%;
  align-content: center;
}  
.col2mid {
  flex: 0 0 50%; /* Each column takes up 50% of the width */
  align-content: center;
  justify-items: center;
}

.firstPhones {
  width: 100%; /* or any specific width like 50%, 200px, etc. */
  height: auto; /* Maintains aspect ratio */
  max-width: 400px; 
  max-height: 700px;
  justify-self: center;
  }
  .secondPhones {
    width: 100%; /* or any specific width like 50%, 200px, etc. */
    height: auto; /* Maintains aspect ratio */
    max-width: 400px; 
    max-height: 700px;
    justify-self: center;
  }
.title {
  color: white;
  font-size: 5vw;
  margin-bottom: -10px;
  margin-left: 10%;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.subtitle {
  color: black;
  font-family: "Rationale", sans-serif;
  font-size: 4vw;
  margin-bottom: -10px;
 }
.p1Text {
  color: white;
  font-size: 1.7vw;
  margin-left: 10%;
  margin-right: 10%;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.p2Text {
  color: black;
  font-size: 1.8vw;

}
.scrollPic {
  position: fixed;
  left: 50%; /* Move the element right by 50% of the viewport width */
  transform: translate(-50%, -50%); /* Center the element precisely */
}
.scrollPicRemove {
  position: fixed;
  opacity: 0;
  left: 50%; /* Move the element right by 50% of the viewport width */
  transform: translate(-50%, -50%); /* Center the element precisely */
}
.shopbtns {
  color: white;
  display: flex; /* Enable Flexbox */
  flex-direction: row; /* Default value, can be omitted */
  height: 5vh;
  width:275px;
  align-items: center;
  margin-left: 10%;
  justify-content:space-between;

}
.shopbtnsbottom {
  color: white;
  display: flex; /* Enable Flexbox */
  flex-direction: row; /* Default value, can be omitted */
  height: 5vh;
  width:275px;
  align-items: center;
  margin-top: 5px;
  justify-content:space-between;
}

.topBanner {
  position: absolute;
  display: flex; /* Enable Flexbox */
  flex-direction: row; /* Default value, can be omitted */
  top: 0;
  height: 10vh;
  width:100%;
  align-items: center;
  
}
.sitename {
  height: 10vh;
  width:10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  color: white; /* Optional: Text color */
  cursor: pointer;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.tabs {
  color: white; /* Optional: Text color */
  height: 10vh;
  width: 35%;
  display: flex; /* Enable Flexbox */
  flex-direction: row; /* Default value, can be omitted */
  justify-content:space-evenly;
  align-items: center;
  font-size: 2vw;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.contactbtn {
  position: absolute; /* Ensure the button is positioned relative to its closest positioned ancestor */
  right: 3%; /* Align the button to the right */
  background-color: #1A83AA;
  height: 50px;
  width: 100px;
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  color: white; /* Optional: Text color */
  cursor: pointer; /* Optional: Change cursor on hover */
  border-radius: 100px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.midPage {
  display: flex;
  flex-direction: column; /* Ensure elements are stacked vertically */
  background-color: rgb(255, 255, 255);
  width: 80%;
  height: 200vh;
padding-top: 100vh;
align-items: center; 
padding-bottom: 50px;
}
.midTop {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20vh;
  color: #252B43;
  justify-content:space-between;
  align-content: center;

}
.midmid {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75vh;
  padding-bottom: 24px;

}
.mid2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.textInfo {
  font-size: 1.8vw;
  color: #ffffff;
  align-content: center;
  text-align: center;
  width: 24vw;
  height: 15vh;
  border-radius: 1vw;
  background-color: #252B43;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}
.bottomBoder{
  position: absolute; /* Ensure the button is positioned relative to its closest positioned ancestor */
  display: flex;
  flex-direction: column;
  bottom: 0; /* Align the button to the right */
  background-color: rgb(37, 43, 67, 0.85);
  height: 120px;
  width: 100%;
  color: white;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}
.bottomtext{
  font-size: 30px;
  margin-bottom: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.tabs p, .contactbtn p {
  cursor: pointer;
  /* Other styles */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 500px) {
  .hero {
    height: 100vh;
    width: 100%;
    align-content:end;
    background: linear-gradient(to bottom, rgba(37, 43, 67, 0.6), rgba(37, 43, 67, 1)), url('../assets/bilder/phonebg.png') no-repeat center center;
    background-size: cover;
  }
  .herocontent {
    display: flex;
    flex-direction: column;
    height: 70%;
    width: 90vw;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 8vw;
    width: 70vw;
  }
  .subtitle {
    font-size: 6vw;
  }

  .p1Text {
    font-size: 4vw;
    width: 70vw;
  }
  .col1 {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }  
  .col2 {
   display: none;
  }
  
  .p2Text {
    position: relative;
    font-size: 4vw;
    bottom: 0;
  }
  .shopbtns {
    width: 70vw;
    justify-content:space-between;
  } 
  .shopbtnsbottom {
    width: 50%;
  }
  .sitename {
    margin-left: 5%;
    font-size: 6vw;
    width: auto;
  }
  .scrollPic {
    display: none;
    padding-bottom: 20%;
    height: 6%;
  }
  .tabs {
    width: 90%;
    font-size: 4vw;
    display:none;
  }
  .contactbtn {
    height: 40px;
    width: 80px;
  }
  .textInfo {
    font-size: 4vw;
    width: 80%;
    height: auto;
    margin: 10px 0;
  }
  .bottomtext {
    font-size: 5vw;
  }
  .midPage {
    width: 90%;
    align-content: center;
  }

  .midTop {
    display: none;
    background-color: yellow;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    padding-top: 5%;
  }
  .midmid {
    display: flex;
    height: 100vh;

}
  .mid2 {
    justify-content: center;
    height: 100vh;
    justify-content: flex-end; /* Aligns children to the right horizontally */
    align-items: flex-end;
  }
  .col1mid {
    flex: 0 0 50%; /* Each column takes up 50% of the width */
    align-content: flex-end;
    justify-items: center;
  }  
  .col2mid {
    flex: 0 0 50%; /* Each column takes up 50% of the width */
    align-content: flex-start;
    margin-top: 20%;
    justify-items: center;
  }
  .firstPhones {
    width: 180%; /* or any specific width like 50%, 200px, etc. */
    height: auto; /* Maintains aspect ratio */
    justify-self: center;
    transform: translateX(-15%) rotate(-30deg) translateY(-15%)  ;
}
.secondPhones {
  width: 180%; /* or any specific width like 50%, 200px, etc. */
  height: auto; /* Maintains aspect ratio */
  justify-self: center;
  transform: translateX(-40%) rotate(40deg) translateY(-15%)  ;
}

}
